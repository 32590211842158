import React, { useState, useEffect } from 'react';
import Layout from "../components/layout";
import Seo from "../components/seo";
import MeteoCard from "../components/MeteoCard";
import MeteoHourlyCard from '../components/MeteoHourlyCard';

function IndexPage() {
  const [meteo, setMeteo] = useState()
  const [meteoCurrent, setMeteoCurrent] = useState()

  useEffect(() => {
    async function loadingData() {
      const rawResponse = await fetch('https://meteo7d.sebastienk.workers.dev/')

      const meteoResponse = await rawResponse.json();
      setMeteo(meteoResponse)
      setMeteoCurrent(meteoResponse.current)


    }

    loadingData()
  }, []);

  function timeConversion(timeStamp) {
    const date = new Date(timeStamp * 1000)
    const hour = date.getHours() + 'h' + date.getMinutes()
    return hour
  }
  let meteoListCurrent
  let meteoForcast
  if (meteoCurrent !== undefined) {

    // loop to handle the hourly forcast components
    let meteoHourly = []
    for (let i = 0; i < 24; i++) {
      meteoHourly.push(<MeteoHourlyCard time={meteo?.hourly[i].dt} temp={meteo?.hourly[i].temp} description={meteo?.hourly[i].weather[0].description} icon={meteo?.hourly[i].weather[0].icon} />)
    }

    const sunrise = meteoCurrent?.sunrise
    const sunset = meteoCurrent?.sunset
    // let dt = meteoCurrent.dt
    const icon = meteoCurrent?.weather[0].icon

    const iconUrl = `https://res.cloudinary.com/dvwjd49at/image/upload/v1648069081/weatherIcons/${icon}.png`

    // Current weather card with hourly forcast in it ______________________________________
    meteoListCurrent = < div className="currentWeather" style={{ display: "flex", alignSelf: "center", flexDirection: "column", height: "53vh", backgroundColor: "rgb(235,235,235)", borderRadius: 10 }}>

      <h2 style={{ marginLeft: 1, alignSelf: "center", marginBottom: 10, marginTop: 10 }}>Paris </h2>
      {/* |0 permet de tronquer les chiffres après la virgule */}
      <h2 style={{ alignSelf: "center", marginBottom: 5 }}>{meteoCurrent?.temp | 0} °C </h2>

      <div style={{ display: "flex", justifyContent: "center", marginBottom: 5 }}>
        <p style={{ marginLeft: 10, alignSelf: "center" }}>{meteoCurrent?.weather[0].description}</p>
        <img style={{ marginLeft: 10, alignSelf: "center", width: 50, height: 50 }} src={iconUrl} alt={meteoCurrent?.weather[0].description} />
      </div>

      <p style={{ marginLeft: 1, alignSelf: "center" }} > Temperature ressentie {meteoCurrent?.feels_like | 0} °C </p>

      <div style={{ display: "flex", justifyContent: "center", marginTop: 10, marginBottom: 10, }}>
        <p >min {meteo?.daily[0].temp.min | 0} °C</p>
        <p style={{ marginLeft: 10 }}>max {meteo?.daily[0].temp.max | 0} °C</p>
      </div>
      {/* Hourly forcast _________________________________________________ */}
      <div style={{ display: "flex", overflowX: "auto" }}>
        {meteoHourly}
      </div>


      <p style={{ marginLeft: 10, marginTop: 10 }}>Lever du soleil {timeConversion(sunrise)}</p>
      <p style={{ marginLeft: 10, marginTop: 10 }}>Coucher du soleil {timeConversion(sunset)}</p>
      {/* <p style={{ marginLeft: 10, marginTop: 10 }}> Dernière mise à jour des données {timeConversion(dt)}</p> */}
    </div>
    // Map for the daily forcast components ______________________________
    meteoForcast = meteo?.daily.map((weatherMap, i) => {

      const tempDate = JSON.stringify(weatherMap.dt)
      return (<MeteoCard key={i} date={tempDate} tempMin={weatherMap.temp.min} tempMax={weatherMap.temp.max} description={weatherMap.weather[0].description} icon={weatherMap.weather[0].icon} />)
    })

  } else {
    meteoListCurrent = < div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", height: "53vh", width: 600, backgroundColor: "rgb(235,235,235)", borderRadius: 10 }}>
      <p style={{ paddingLeft: "10px" }}> Désolé il semblerait que les données météos soient indisponibles pour le moment </p>
    </div>
  }
  return (
    <Layout style={{ height: "100vh" }}>
      <Seo title="Météo" />
      <div style={{ display: "flex", justifyContent: "center", }}>
        {meteoListCurrent}
      </div>
      <div style={{ display: "flex", justifyContent: "center", flexWrap: "wrap", marginTop: 30 }}>
        {meteoForcast}
      </div>

    </Layout>)
}

export default IndexPage
