import React from "react";

function MeteoCard(props) {


    function dateConversion(Dday) {
        const dateParse = JSON.parse(Dday)
        const dateConverted = new Date(dateParse * 1000)
        const dateString = JSON.stringify(dateConverted.toUTCString())
        const dateCuted = dateString.slice(1, 12)

        // console.log(dateConverted.getDay()); jour de la semaine

        return dateCuted
    }

    return (
        <div style={{ backgroundColor: "rgb(235,235,235)", margin: 5, padding: 5, borderRadius: 5, width: 150 }}>
            <h3 style={{ textAlign: "center", marginBottom: 1 }}>Paris</h3>
            <p style={{ textAlign: "center" }}>{dateConversion(props.date)}</p>
            <div style={{ display: "flex", alignItems: "center" }}>
                <p> {props.description} </p>
                <img style={{ marginLeft: 5, alignSelf: "flex-end", width: 35, height: 35 }} src={`https://res.cloudinary.com/dvwjd49at/image/upload/v1648069081/weatherIcons/${props.icon}.png`} alt={props.description} width="10%" />
            </div>
            <div>
                <p> max {props.tempMax | 0} °C </p>
                <p> min {props.tempMin | 0} °C </p>
            </div>
        </div>
    )
}
export default MeteoCard